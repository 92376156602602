
// import { reactLocalStorage } from 'reactjs-localstorage';
// let aepsConfig = JSON.parse(reactLocalStorage.get('aepsConfig'));
export const GET_NAME = "Verify A/C No";
export const RE_CHECK = "RE-Verify A/C";
export const EMPTY = "";
export const SIGN_IN_URL = "/login";
export const UNAUTHORISED_CODE = "403";
export const UNAUTHORISED_CODE_401 = "401";
// export const BIOMETRIC_CAPTURE_API = aepsConfig.captureUrl;  //"https://127.0.0.1:11100/rd/capture";
// export const BIOMETRIC_CAPTURE_XML_DATA = aepsConfig.xmlData;// '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" pgCount="2" format="0" pidVer="2.0" timeout="10000" pTimeout="20000" posh="UNKNOWN" env="P" /> <CustOpts><Param name="mantrakey" value="" /></CustOpts> </PidOptions>';

export const APP_INFO = "APP_INFO";
export const USER_ROLE = "/role";
export const APP_INFO_URL = "/appinfo";
export const DMT_SETTING_URL = "/dmtSetting";
export const UTILITY_OFFLINE_PAYMENT_TAB = "UTILITY_OFFLINE_PAYMENT_TAB";

export const MOBILE_SEND_OTP = "/users/mobileNumber";
export const MOBILE_VERFIY_SEND = "/users/mobileNumberSend";
export const MOBILE_SEND_VERFIY = "/users/mobileNumberVerify";
export const GET_MYPROFILE = "/users/getprofile";
export const PARTNER_REGISTER = "/users/customer/register";

export const CHANGE_PASSWORD = "/users/changepassword";

export const FORGET_PASSWORD = "/users/forgetpassword";
export const FORGET_USERID = "/users/forgetuserid";
export const FORGET_PASSWORD_MOBILE_NUMBER = "/users/forgetpasswordmobilenumber";
export const STATE_API = "/prepaid/state";
export const KYC_UPDATE = "/users/KYCUpdate";
export const ADMIN_ACCEPT_PROFILE = "/admin/acceptProfile";
export const UPDATE_USER_PROFILE = "/users/updateprofile"
export const CREATE_ALL_TYPE_USERS = "/users/createUser"
export const CREATE_USERS_STAFF = "/users/createStaff"
export const GET_PARENT_PROFILE = "/users/getParentProfile"
export const POST_OPERATIVE_WALLET_RECHARGE = "/admin/oprativeBalanceRecharge"
export const GET_ADMIN_DASHBOARD_DATA = "/admin/getAdminDashboardData"
export const GET_MERCHANT_WALLET_BALANCE = "/admin/wallet/marchantBalance";

export const POST_USER_CONTROL_STATUS = "/users/userControlStatus";

export const UPLOAD_FILE = "/upload-file";
export const UPLOAD_IMAGE_FILE = "/upload-image-file";
export const UPLOAD_SHOP_IMAGE_FILE = "/upload-shop-image-file";
export const UPLOAD_AEPS_IMAGE_FILE = "/upload-aeps-image-file";

export const MOBILE_RECHARGE_OPERATORS = "/prepaid/mobile-operators";
export const MOBILE_RECHARGE_VIEW_PLAN = "/prepaid/mobile-recharge-viewplan";
export const MOBILE_RECHARGE_BESTOFFER = "/prepaid/mobile-recharge-bestoffer";
export const MOBILE_RECHARGE_PAYMENT = "/prepaid/mobile-recharge-payment";
export const MOBILE_RECHARGE_REPORT = "/prepaid/mobile-recharge-report";

export const DTH_PROVIDERS = "/prepaid/dth-providers";
export const DTH_CUSTOMER_INFO = "/prepaid/dth-customer-info";
export const DTH_BEST_OFFER = "/prepaid/dth-best-offer";
export const DTH_HEAVY_REFRESH = "/prepaid/dth-heavy-refresh";
export const DTH_RECHARGE_PAYMENT = "/prepaid/dth-recharge-payment";
export const DTH_RECHARGE_REPORT = "/prepaid/dth-recharge-report";

export const BBPS_BILLER_CATEGORY = '/bbps/getAllBillerCategory';
export const BBPS_ELETRICITY_OPERATORS = "/bbps/getBillerByBillerCategory";
export const BBPS_POST_BILL_FETCH_REQUEST = "/bbps/postBillFetchRequest";
export const BBPS_POST_BILL_PAYMENT_REQUEST = "/bbps/postBillPaymentRequest";
export const BBPS_POST_BILL_PAYMENT_STATUS = "/bbps/postBillPaymentStatus";
export const BBPS_POST_BILL_PAYMENT_HISTORY = "/bbps/getBbpsTransactionHistory";

export const ELETRICITY_OPERATORS = "/payment/eletricity-operators";
export const ELETRICITY_VIEW_BILL = "/payment/eletricity-view-bill";
export const ELETRICITY_BILL_PAYMENT = "/payment/eletricity-bill-payment";
export const ELETRICITY_BILL_PAYMENT_REPORT = "/payment/eletricity-bill-payment-report";

export const WATER_OPERATORS = "/payment/water-operators";
export const WATER_VIEW_BILL = "/payment/water-view-bill";
export const WATER_BILL_PAYMENT = "/payment/water-bill-payment";
export const WATER_BILL_PAYMENT_REPORT = "/payment/water-bill-payment-report";

export const GAS_OPERATORS = "/payment/gas-operators";
export const GAS_VIEW_BILL = "/payment/gas-view-bill";
export const GAS_BILL_PAYMENT = "/payment/gas-bill-payment";
export const GAS_BILL_PAYMENT_REPORT = "/payment/gas-bill-payment-report";

export const INSURANCE_OPERATORS = "/payment/insurance-operators";
export const INSURANCE_VIEW_BILL = "/payment/insurance-view-bill";
export const INSURANCE_BILL_PAYMENT = "/payment/insurance-bill-payment";
export const INSURANCE_BILL_PAYMENT_REPORT = "/payment/insurance-bill-payment-report";

export const ADMIN_ALL_MAKER_FUND_REQUEST = "/admin/getAllAdminMakerFundRequest";
export const ADMIN_ALL_CHEKER_FUND_REQUEST = "/admin/getAllAdminChekerFundRequest";
export const ADMIN_FUND_REQUEST_STATUS_CHANGE_REQUEST = "/admin/fundRequestApproveOrReject";
export const ADMIN_UTILITY_PAYMENT_OFFLINE_STATUS_CHANGE_REQUEST = "/admin/utilityPaymentOfflineApproveOrReject";
export const ADMIN_DAILY_TRANSACTION_REPORT = "/admin/getDailyTransactionReport";
export const ADMIN_AEPS_LOGIN_REPORT = "/admin/getAepsLoginReport";

export const ADMIN_MAKER_UTILITY_PAYMENT_OFFLINE = "/admin/getUtilityPaymentOfflineMaker";
export const ADMIN_APPROVE_UTILITY_PAYMENT_OFFLINE = "/admin/getUtilityPaymentOfflineApprove";

export const ADMIN_GET_ALL_USERS = "/admin/getAllApprovedUser";
export const ADMIN_GET_ALL_APPROVED_EXPORT_USERS = "/admin/getAllApprovedUserExport";
export const ADMIN_GET_ALL_APPROVED_USERS = "/admin/getAllApprovedUser";
// export const ADMIN_GET_ALL_EKYC_APPROVED_USERS = "/admin/getAllEKycApprovedUser";
export const ADMIN_AADHAR_PENDING_KYC_USERS = "/admin/getUsersAadhaarPending";
export const ADMIN_GET_ALL_KYC_PENDING_USERS = "/admin/getAllPendingKycUser";
export const GST_IN_VERIFY = "/users/gstInVerify";
export const PANCARD_VERIFY = "/users/pancardVerify";
export const JOIN_US_PANCARD_VERIFY = "/users/joinUsPancardVerify";
export const CREATE_USER_PANCARD_VERIFY = "/users/createUserPancardVerify";
export const AADHARCARD_OTP_VERIFY = "/users/aadharcardOTPVerify";
export const CREATE_USER_AADHARCARD_VERIFY = "/users/aadharcardCreateUserVerify";
export const CREATE_USER_AADHARCARD_OTP_VERIFY = "/users/aadharcardCreateUserOtpVerify";

export const AADHAAR_CARD_SEND_OTP_CODE = "/users/aadharcardSendOtpCodeVerify";

export const AADHARCARD_VERIFY = "/users/aadharcardVerify";
export const SAVE_MPIN_NUMBER = "/users/saveMpinNumber";
export const MPIN_NUMBER_VERIFY = "/users/mPinNumberVerify";
export const CHANGE_USER_STATUS = "/users/changeUserStatus";

export const SEND_REQUEST_MONEY_SAVE = "/account/sendRequestMoney";
export const SEND_COD_REQUEST_MONEY_SAVE = "/account/codSendRequestMoney";

export const GET_ALL_MY_COD_SEND_REQUEST_MONEY = "/account/getAllMyCodSendRequest";
export const GET_ALL_MY_COD_RECEIVED_REQUEST_MONEY = "/account/getAllMyCodReceivedRequest";
export const COD_REJECT_AND_APPROVED_REQUEST = "/account/codRejectAndApproveRequest";
export const GET_ALL_MY_COD_APPROVED_REQUEST_MONEY = "/account/getCodApprovedRequestMoney";

export const POST_FUND_TOPUP_SAVE = "/account/fundTopupSave";

export const INSTANT_TOPUP_SAVE = "/account/instant-topup-save";
export const INSTANT_TOPUP_KYC_CHARGE = "/account/instant/instantKycCharge";
export const INSTANT_TOPUP_GET = "/account/instant-topup-getall";
export const INSTANT_TOPUP_APPROVE_REQUEST = "/account/postIntantPayApproveRequest";

export const FUND_REQUEST_SAVE = "/account/fund-request-save";
export const FUND_REQUEST_GET = "/account/fund-request-getall";
export const GET_ADMIN_FUND_REQUEST_REPORT = "/admin/getFundRequestReport";
export const GET_MATM_REGISTRATION_LIST = "/service/matm/getRegistrationList";
export const POST_MATM_REGISTRATION_STATUS_APPROVED = "/service/matm/matmRegisterApproved";
export const GET_MY_FUND_REQUEST_REPORT = "/users/getMyFundRequestReport";

export const BANK_DETAIL = "/users/bankdetail";
export const GET_ALL_BANK_DETAIL_INFO = "/users/getAllActivebankDetail";
export const GET_ALL_MY_SEND_REQUEST_MONEY = "/account/getAllMySendRequest";
export const GET_ALL_MY_APPROVED_REQUEST_MONEY = "/account/getApprovedRequestMoney";
export const GET_ALL_MY_RECEIVED_REQUEST_MONEY = "/account/getAllMyReceivedRequest";

export const GET_ALL_MY_SEND_PAYMENT_DEPOSIT = "/account/getAllSendPaymentDeposit";
export const GET_ALL_MY_RECEVIED_PAYMENT_DEPOSIT = "/account/getAllReceviedPaymentDeposit";
export const SAVE_PAYMENT_DEPOSIT = "/account/paymentDeposit";
export const REJECT_AND_APPROVED_REQUEST = "/account/rejectAndApproveRequest";
export const PAYMENT_DEPOSIT_REJECT_AND_APPROVED_REQUEST = "/account/paymentDepositAcceptAndReject";

export const SAVE_MOVE_TO_DISTRIBUTOR = "/account/moveToDistributor";
export const GET_ALL_MOVE_TO_DISTRIBUTOR_REPORTS = "/account/getAllMoveToDistributor";

export const GET_ALL_CHANNEL_WALLET_LEDGER_REPORTS = "/account/channelWalletLedger";
export const GET_MY_LEDGER_REPORTS = "/ledger/getMyLedger";

export const POST_USER_TRANSFER = "/admin/userTransfer";
export const GET_MY_CHILD_USERS = "/admin/getChildUser";
export const GET_All_OPERATOR_LIST = "/admin/getAllOperatorList";

export const PACKAGE_DETAIL_LIST = "/package/getPackageDetail";
export const PACKAGE_LIST = "/package/getAllPackages";
export const PACKAGE_SAVE = "/package/save";
export const PACKAGE_UPDATE = "/package/update";
export const PACKAGE_DELETE = "/package/delete";
export const MY_WALLET_HISTORY = "/wallet/getWalletHistory";

export const GET_SERVICES = "/service";
export const GET_SERVICES_LIST = "/serviceList";
export const GET_SERVICES_OPERATOR = "/service/operator";
export const GET_SERVICES_OPERATOR_LIST = "/getServiceOperatorList";
export const GET_ALL_SERVICES_OPERATOR = "/getServiceOperator";
// Slab 
export const SLABS_NAMES = "/slab";
export const SLABS_SAVE = "/slab/save";
export const SLABS_LIST = "/slab/getAllSlab";
export const SLABS_UPDATE = "/slab/update";
export const SLABS_DELETE = "/slab/delete";

export const CHECK_CARD_TYPE = "/payu/checkCardType";
export const GET_PAYU_HASH = "/payu/getHash";

export const IFSC_CODE_VERIFY = "/upi/ifscCodeVerify";
export const GET_BANK_NAME_FROM_ACCOUNT_NUMBER_IN_BANK_SETTING = "/upi/getBankNameOfBankSetting";
export const GET_BANK_NAME_FROM_ACCOUNT_NUMBER = "/upi/getBankName";
export const GET_CUSTOMER_BANK_NAME_FROM_ACCOUNT_NUMBER = "/upi/getBeneBankNameInMobileApp";

export const SAVE_BANK_SETTING_API = "/users/bankSettingSave";
export const SET_DEFAULT_BANK_SETTING = "/users/setDefaultBankSettingSave";

export const GET_BANK_SETTING_LIST = "/users/bankSettingGetAll";
export const BANK_SETTING_CHANGE_STATUS = "/users/bankSettingChangeStatus";
export const BANK_SETTING_APMIN_APPROVE_REQUEST = "/users/bankSettingAdminApprove";
export const BANK_SETTING_DELETE = "/users/bankSetting";

export const UPI_VERIFY = "/upi/upiverify";
export const UPI_FROM_MOBILE_NUMBER = "/upi/mobileNumberGetUpi";
export const UPI_POST_TRANSACTION = "/upi/upiTransaction";

export const SEARCH_PARTNER_LEDGER_API = "/ledger/sendPartnerLedger";
export const PROFILE_PIN_MATCH_API = "/ledger/matchPinPartnerLedger";

export const POST_AEPS_LOGIN = "/aeps/aeps-login";
export const POST_AEPS_LOGIN_STATUS = "/aeps/aeps-login-status";
export const GET_AEPS_DEVICE_CONFIG = "/aeps/getAePSDeviceConfig";
export const POST_AEPS_EKYC_INITIATE = "/aeps/ekyc-initiate";
export const POST_AEPS_EKYC_VALIDATE = "/aeps/ekyc-validate";
export const POST_AEPS_BALANCE_ENQUIRY = "/aeps/balance-enquiry";
export const POST_AEPS_CASH_WITHDRAWAL = "/aeps/cash-withdrawal";
export const POST_AEPS_MINI_STATEMENT = "/aeps/min-statement";
export const GET_AEPS_BANK_LIST = "/aeps/bank-list";
export const GET_AEPS_HISTORY_LIST = "/aeps/aeps-history";
export const GET_BIO_DEVICE_LIST = "/aeps/getBioDeviceList";
export const POST_BIO_DEVICE_LIST = "/aeps/postBioDeviceList";

export const GET_DMT_BANK_LIST = "/aeps/dmt/getBankList";
export const GET_DMT_NEW_BANK_LIST = "/aeps/dmt/getBankListNew";
export const GET_DMT_EKO_BANL_LIST = "/aeps/dmt/getEkoBankList";
export const GET_DMT_BENEFICIARY_LIST = "/aeps/dmt/getBeneficiaryList";
export const GET_DMT_IMPORT_BENEFICIARY_LIST = "/aeps/dmt/getImportBeneficiaryList";
export const GET_DMT_BENEFICIARY_DETAIL = "/aeps/dmt/getBeneficiaryDetails";
export const UPDATE_DMT_BENEFICIARY_DETAIL = "/aeps/dmt/updateBeneficiary";
export const UPDATE_DMT_BENEFICIARY_STATUS = "/aeps/dmt/activeAndDeactiveBane";
export const POST_DMT_CUSTOMER_INFO = "/aeps/dmt/getCustomerInfo";
export const POST_DMT_CREATE_CUSTOMER = "/aeps/dmt/createCustomer";
export const POST_DMT_VERIFY_CUSTOMER = "/aeps/dmt/verifyCustomerIdentity";
export const POST_SHOP_REGISTERD = "/aeps/postShopRegister";
export const GET_SHOP_REGISTERD = "/aeps/getShopRegister";
export const GET_SHOP_REGISTRATION_LIST = "/aeps/shopRegisterList";

export const POST_DMT_CHECK_SERVICE_TO_MOBILENUMBER = "/aeps/dmt/checkDMTServiceToMobileNumber";

export const GET_DMT_INSTANT_PAY_BENEFICIARY_LIST = "/service/dmt/v2/getBeneficiaryList";
export const POST_DMT_INSTANT_PAY_CUSTOMER_INFO = "/service/dmt/v2/getCustomerInfo";
export const POST_DMT_INSTANT_PAY_CREATE_CUSTOMER = "/service/dmt/v2/createCustomer";
export const POST_DMT_INSTANT_PAY_ADD_BENEFICIARY = "/service/dmt/v2/addBeneficiary";
export const POST_DMT_INSTANT_PAY_OTP_VERIFICATION = "/service/dmt/v2/customerOTPVerify";
export const UPDATE_DMT_INSTANT_PAY_BENEFICIARY_STATUS = "/service/dmt/v2/activeAndDeactiveBane";
export const UPDATE_DMT_INSTANT_PAY_BENEFICIARY_DETAIL = "/service/dmt/v2/updateBeneficiary";
export const POST_DMT_INSTANT_PAY_INITIATE_TRANSACTION = "/service/dmt/v2/initiateTransaction";
export const POST_DMT_INSTANT_PAY_RE_INITIATE_TRANSACTION = "/service/dmt/v2/reInitiateTransaction";
export const GET_DMT_INSTANT_PAY_TRANS_HISTORY = "/service/dmt/v2/transactionHistory";
export const POST_DMT_INSTANT_PAY_TRANSACTION_INQUIRY = "/service/dmt/v2/getTransactionInquiry";
export const POST_DMT_INSTANT_PAY_TRANSACTION_INVOICE = "/service/dmt/v2/transactionInvoice";
export const POST_DMT_INSTANT_PAY_MERCHANT_LIST = "/service/dmt/v2/getIpayMerchantList";
export const POST_DMT_INSTANT_PAY_MERCHANT_UPDATE = "/service/dmt/v2/postIpayMobileUpdate";
export const POST_DMT_INSTANT_PAY_MERCHANT_VERIFY = "/service/dmt/v2/postIpayMobileUpdateVerify";

export const POST_DMT_PAY_SPRINT_CREATE_CUSTOMER = "/service/dmt/v3/createCustomer";
export const POST_DMT_PAY_SPRINT_CUSTOMER_INFO = "/service/dmt/v3/getCustomerInfo";
export const POST_DMT_PAY_SPRINT_OTP_VERIFICATION = "/service/dmt/v3/customerOTPVerify";
export const POST_DMT_PAY_SPRINT_CUSTOMER_DETAIL = "/service/dmt/v3/customerUpdateDetail";
export const GET_DMT_PAY_SPRINT_BENEFICIARY_LIST = "/service/dmt/v3/getBeneficiaryList";
export const POST_DMT_PAY_SPRINT_INITIATE_TRANSACTION = "/service/dmt/v3/initiateTransaction";
export const POST_DMT_PAY_SPRINT_RE_INITIATE_TRANSACTION = "/service/dmt/v3/reInitiateTransaction";
export const POST_DMT_PAY_SPRINT_ADD_BENEFICIARY = "/service/dmt/v3/addBeneficiary";
export const UPDATE_DMT_PAY_SPRINT_BENEFICIARY_STATUS = "/service/dmt/v3/activeAndDeactiveBane";
export const UPDATE_DMT_PAY_SPRINT_BENEFICIARY_DETAIL = "/service/dmt/v3/updateBeneficiary";
export const POST_DMT_PAY_SPRINT_TRANSACTION_INQUIRY = "/service/dmt/v3/getTransactionInquiry";

export const POST_DMT_PAY_SPRINT_CONFIRM_INITIATE_TRANSACTION = "/service/dmt/v3/confirmInitiateTransaction";

export const POST_PAY_SPRINT_REFUND_OTP_REQUEST = "/service/dmt/v3/refundOtpBaseTransaction";
export const POST_PAY_SPRINT_REFUND_REQUEST = "/service/dmt/v3/refundTransaction";

export const POST_PAYOUT_CHECK_EXIST_CUSTOMER_DETAIL = "/payment/payout/checkExistPayoutCustomer";
export const POST_PAYOUT_ADD_CUSTOMER_DETAIL = "/payment/payout/addCustomer";
export const POST_PAYOUT_CUSTOMER_DETAIL = "/payment/payout/getCustomerDetail";
export const POST_PAYOUT_ADD_BENEFICIARY = "/payment/payout/addBeneficiary";
export const POST_PAYOUT_GET_BENEFICIARY = "/payment/payout/getBeneficiaryList";
export const POST_PAYOUT_UPDATE_BENEFICIARY_DETAIL = "/payment/payout/updateBeneficiary";
export const POST_PAYOUT_UPDATE_STATUS_BENEFICIARY = "/payment/payout/activeAndDeactiveBane";
export const POST_PAYOUT_INITIATED_TRANSACTION = "/payment/payout/payoutTransactionInitiated";
export const POST_PAYOUT_PAYMENT_TRANSFER = "/payment/payout/payoutPaymentTransaction";
export const POST_PAYOUT_GET_TRANSACTION_HISTORY = "/payment/payout/transactionHistory";
export const POST_PAYOUT_ADMIN_GET_TRANSACTION_HISTORY = "/payment/payout/getMoneyTransferReport";
export const POST_PAYOUT_ADMIN_GET_TRANSACTION_FAILED_HISTORY = "/payment/payout/getFailTransactionHistory";
export const POST_PAYOUT_GET_TRANSACTION_INQUIRY = "/payment/payout/getTransactionInquiry";
export const POST_PAYOUT_REFUND_FAILED_TRANSACTION = "/payment/payout/refundFailTransaction";
export const POST_PAYOUT_TRANSACTION_INVOICE = "/payment/payout/transactionInvoice";
export const POST_PAYOUT_MARK_FAIL_TRANSACTION = "/payment/payout/postFailTransaction";

export const POST_AEPS_EKYC_EKO_STEP_1 = "/aeps/apesEkycEkoStep1";
export const POST_AEPS_EKYC_EKO_STEP_2 = "/aeps/apesEkycEkoStep2";
export const POST_AEPS_EKYC_EKO_STEP_3 = "/aeps/apesEkycEkoStep3";
export const POST_AEPS_EKYC_EKO_STEP_4 = "/aeps/apesEkycEkoStep4";
export const POST_AEPS_EKYC_EKO_STEP_5 = "/aeps/apesEkycEkoStep5";
export const POST_AEPS_EKYC_EKO_STEP_6 = "/aeps/apesEkycEkoStep6";
export const POST_AEPS_SHOP_CONTROL_STATUS = "/aeps/shopControlStatus";


export const GET_ADDRESS_FROM_LAT_LONG = "/aeps/getAddressFromLatLong";
export const POST_DMT_ADD_BENEFICIARY = "/aeps/dmt/addBeneficiary";
export const GET_DMT_TRANS_HISTORY = "/aeps/dmt/transactionHistory";
export const POST_DMT_INITIATE_TRANSACTION = "/aeps/dmt/initiateTransaction";
export const GET_SYNC_BENEFICIARY_LIST = "/aeps/dmt/syncBeneficiaryList";
export const POST_DMT_REINITIATE_TRANSACTION = "/aeps/dmt/reInitiateTransaction";
export const GET_DMT_TRANSACTION_INQUIRY = "/aeps/dmt/getTransactionInquiry";
export const POST_DMT_REFUND_TRANSACTION = "/aeps/dmt/refundTransaction";
export const POST_DMT_REFUND_OTP_REQUEST_TRANSACTION = "/aeps/dmt/refundOtpBaseTransaction";
export const POST_DMT_TRANSACTION_INVOICE = "/aeps/dmt/transactionInvoice";
export const POST_EKO_FIND_BANK_DETAIL = "/aeps/dmt/getBankInfoFromShortCode";
export const GET_DMT_TRANSACTION_FAILED = "/aeps/dmt/getFailTransactionHistory";
export const GET_DMT_TRANSACTION_REFUNDED = "/aeps/dmt/getRefundedTransactionHistory";
export const GET_DMT_TRANSACTION_DETAIL_HISTORY = "/aeps/dmt/getDmtTransactionDetailHistory";
export const POST_DMT_REFUND_FAILED_TRANSACTION = "/aeps/dmt/refundFailTransaction";
export const GET_DMT_PARTNER_REFUND_PENDING_HISTORY = "/aeps/dmt/getDmtPartnerRefundPendingHistory";
export const POST_DMT_TRANSACTION_UPDATE = "/aeps/dmt/transactionUpdate";

export const POST_DMT_FAIL_TRANSACTION = "/aeps/dmt/postFailTransaction";
export const GET_DMT_TRANS_REPORT = "/admin/getMoneyTransferReport";
export const GET_AEPS_WITHDRAWAL_REPORT = "/admin/getAePSWithdrawalReport";
export const GET_AEPS_WITHDRAWAL_INQUIRY = "/aeps/getTransactionInquiry";
export const GET_AEPS_3WAYRECON_REPORT = "/admin/getAeps3WayReport";
export const GET_ADMIN_MOBILE_RECHARGE_REPORT = "/admin/getMobileRechargeReport";
export const GET_ADMIN_DTH_RECHARGE_REPORT = "/admin/getDthRechargeReport";
export const GET_ADMIN_COMMISSION_TRANSACTION_REPORT = "/admin/getCommissionTransactionReport";
export const GET_ADMIN_INSTANT_STATEMENT_REPORT = "/admin/getInstantStatementReport";

export const GET_COMMISSION_LIST = "/users/getCommissionList";
export const SAVE_COMPLAINT = "/complaint/save";
export const GET_CLIENT_COMPLAINT_LIST = "/complaint/getAllClient";
export const GET_ADMIN_COMPLAINT_LIST = "/complaint/getAllAdmin";
export const PUT_UPDATE_COMPLAINT_LIST = "/complaint/update";

export const GET_USER_MONTHLY_COMMISSION_CALCULATE = "/users/monthlyCommissionCalculate";
export const POST_SAVE_COMMISSION_WITHDRAWAL = "/users/commissionWithdrawal";
export const GET_COMMISSION_WITHDRAWAL_HISTORY = "/users/getCommissionWithdrawalHistoryList";

export const FING_PAY_ONBOARDING = "/fing/onboarding";
export const FING_PAY_CHECK_EKYC_STATUS = "/fing/getKycStatus";
export const FING_PAY_CHECK_EKYC_SEND_OTP = "/fing/eKycSendOtp";
export const FING_PAY_CHECK_EKYC_RESEND_OTP = "/fing/eKycResendOtp";
export const FING_PAY_CHECK_EKYC_SEND_OTP_VERIFY = "/fing/eKycSendOtpVerify";
export const FING_PAY_CHECK_EKYC_BIOMETRIC_REQUEST = "/fing/eKycBiometricLogin";
export const FING_PAY_2FA_BIOMETRIC_REQUEST = "/fing/twoFABiometricLogin";
export const FING_PAY_KYC_STATUS_CHANGED = "/fing/changeKycStatus";
export const FING_PAY_BANK_LIST = "/fing/getBanklist";
export const FING_PAY_BALANCE_ENQUIRY_API = "/fing/balanceEnquiry";
export const FING_PAY_CASH_WITHDRAWAL_API = "/fing/cashWithDrawal";
export const AEPS_REGISTER_DOWNLOAD_HISTORY = "/fing/getAepsRegisterHistory";
export const AEPS_REGISTER_DOWNLOAD_ZIP = "/fing/aepsRegisterDownload";
export const AEPS_REGISTER_SAVE = "/fing/aepsRegister";
export const TRANSACTION_LOG_HISTORY = "/transaction/getLogHistory";
export const GET_UPI_LOAD_MONEY_REPORT = "/upi/getUpiLoadMoneyReport";
export const POST_SETTLEMENT_TO_BANK_SAVE = "/payment/settlement/postSettleToBankTransaction";
export const GET_SETTLEMENT_TO_BANK_ADMIN_HISTORY = "/payment/settlement/getSettlementToBankAdminReport";

export const QR_CODE_CHECK_CALLBACK_API = "/upi/qrcode/checkCallbackTransaction";
export const SAVE_QR_CODE_MINI = "/upi/qrcode/generateTransactionMini";
export const SAVE_QR_CODE_MINI_CHANGE_STATUS = "/upi/qrcode/generateQRMiniStatus";
export const GET_QR_CODE_MINI_REPORT = "/upi/qrcode/getGenerateTransactionMiniReport";

export const POST_UPI_TRANSFER_CUSTOMER_INFO = "/upi/transfer/getCustomerInfo";
export const POST_UPI_TRANSFER_ADD_CUSTOMER = "/upi/transfer/addCustomer";
export const GET_UPI_TRANSFER_BENEFICIARY_LIST = "/upi/transfer/getBeneficiaryList";
export const POST_UPI_TRANSFER_SYNC_BENEFICIARY_LIST = "/upi/transfer/syncVPABeneficiary";
export const POST_UPI_TRANSFER_CHANGE_BENEFICIARY_STATUS = "/upi/transfer/activeAndDeactiveBane";
export const POST_SEND_MATM_OFFER_API = "/admin/sendMATMOffer";
export const POST_MATM_REGISTRATION_API = "/service/matm/saveMatmRegistration";
export const GET_MATM_MY_REGISTRATION_API = "/service/matm/getMyRegistration";
export const DELETE_MATM_REGISTRATION_API = "/service/matm/deleteRegistration";
export const GET_TRANSACTION_REPORT_API = "/service/matm/getTransactionReport";
export const GET_MATM_ORDER_LIST_API = "/service/matm/getMATMOrderList";
export const POST_MATM_ORDER_STATUS_API = "/service/matm/matmOrderStatusApproved";
export const POST_AEPS_MONTHLY_COMMISSION = "/aeps/dmt/monthlyDmtCommissionCronJob";


export const GET_ADVERTISEMENT_LIST_API = "/admin/advertisement/getAdvertisementList";
export const POST_ADVERTISEMENT_LIST_API = "/admin/advertisement/addAndEditAdvertisement";